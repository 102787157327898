import React from "react";
import {Box, BoxProps} from "@chakra-ui/core";
import BaseContent from "@pency/components/structure/Content";

const Content: React.FC<BoxProps> = ({children, ...props}) => (
  <Box {...props}>
    <BaseContent maxWidth={{base: "100%", xl: "1280px"}}>{children}</BaseContent>
  </Box>
);

export default Content;
