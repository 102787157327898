import {Text} from "@chakra-ui/core";
import styled from "@emotion/styled";

const Heading = styled(Text)`
  font-family: "Recoleta-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue",
    sans-serif !important;
  font-style: normal;
  line-height: 3.7rem;
  letter-spacing: -0.5px;

  @media only screen and (max-width: 768px) {
    line-height: 2.5rem;
  }
`;

export default Heading;
